import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'alpha',

  ping: {
    clientId: '7e56d5dc-3798-47a2-af0a-df94a1bf67d5',
    clientScope: 'openid ecomm_api',
    stsAuthority: 'https://nplogin.wingstop.com/as',
  },
  ecomm: {
    baseUrl: 'https://ecomm.alpha.us.regional.dev.cf.10piececombo.com',
    menuUrl: 'https://ecomm.alpha.us.regional.dev.cf.10piececombo.com',
    webSocketUrl: 'wss://websocket.alpha.us.regional.dev.cf.10piececombo.com'
  },
  ngfeBaseUrl: 'https://alpha.dev.ordering.wingstop.com',
};
